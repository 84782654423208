<!-- 虚拟账号完善资料页面 -->
<template>
    <div class="login flex-align" v-wechat-title="`双优云桥-智慧研修平台`">
        <ul class="header flex-align-between">
            <li>
                <a href="https://news.ys.cnuen.net/#/home" target="_blank" rel="noopener noreferrer">
                    <img src="@/assets/images/home/cun_new_logo_copy.png" alt />
                </a>
            </li>
        </ul>
        <div class="form_box">
            <div class="title">完善信息</div>
            <el-form v-if="step==1" :model="form" :rules="rules" ref="ruleForm" label-width="100px"
                :label-position="labelPosition">
                <el-form-item label="姓名:" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="性别:" prop="sex">
                    <el-select v-model="form.sex" placeholder="请选择性别">
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号:" prop="phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="身份证号:" prop="idcard">
                    <el-input v-model="form.idcard"></el-input>
                </el-form-item>
                <el-form-item label="地区:" prop="address">
                    <el-cascader :options="address" :props="{ expandTrigger: 'hover',checkStrictly: true }"
                        @change="handleChange"></el-cascader>
                </el-form-item>
                <el-form-item label="学校:" prop="school">
                    <el-select v-model="form.school" placeholder="请选择学校">
                        <el-option :label="item.schoolName" :value="item.schoolId" v-for="(item,index) in schoolList"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学科:" prop="subject">
                    <el-select v-model="form.subject" placeholder="请选择学科">
                        <el-option :label="item.dictLabel" :value="item.dictCode" v-for="(item,index) in subject"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="职务:" prop="position">
                    <el-select v-model="form.position" placeholder="请选择职务">
                        <el-option :label="item.name" :value="item.id" v-for="(item,index) in position" :key="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="组别:" prop="userGroup">
                    <el-select v-model="form.userGroup" placeholder="请选择组别">
                        <el-option :label="item.label" :value="item.id" v-for="(item,index) in userGroup" :key="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit('ruleForm')">确定</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </el-form-item>
            </el-form>
            <el-form v-if="step==2" :model="form2" label-width="80px" :label-position="labelPosition">
                <el-form-item label="新密码:">
                    <el-input v-model="form2.psd"></el-input>
                </el-form-item>
                <el-form-item label="确认密码:">
                    <el-input v-model="form2.psd_confirm"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmitPsd">确定</el-button>
                    <!-- <el-button @click="onCancelPsd">取消</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <!-- 登录弹窗 -->
        <el-dialog :visible.sync="dialogVisible" width="40%" :show-close="false" :close-on-click-modal="false"
            :close-on-press-escape="false" custom-class="myDialog">
            <div class="tips flex-align-center">
                请用手机号
                <span>{{phone}}</span>去
                <span class="login_btn" @click="login">登录</span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                step: 1,
                labelPosition: 'right',
                form: {
                    name: "",
                    sex: '',
                    phone: '',
                    idcard: '',
                    address: '',
                    userGroup: '',
                    position: '',
                    subject: '',
                    school: ''
                },
                form2: {
                    psd: '',
                    psd_confirm: "",
                },
                userGroup: [],
                position: [],
                subject: [],
                schoolList: [{
                        schoolName: '学校1',
                        schoolId: 1
                    },
                    {
                        schoolName: '学校2',
                        schoolId: 2
                    },
                    {
                        schoolName: '学校3',
                        schoolId: 3
                    },
                ],
                userId: '',
                phone: '',
                address: [],
                rules: {
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }],
                    sex: [{
                        required: true,
                        message: '请选择性别',
                        trigger: 'change'
                    }],
                    phone: [{
                            required: true,
                            message: '请输入手机号',
                            trigger: 'blur'
                        },
                        {
                            pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/,
                            message: '手机号格式不对',
                            trigger: 'blur'
                        }
                    ],
                    idcard: [{
                        required: true,
                        message: '请输入身份证',
                        trigger: 'blur'
                    }, {
                        pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                        message: '请输入正确的身份证号码',
                        trigger: 'blur'
                    }],
                    address: [{
                        required: true,
                        message: '请选择地区',
                        trigger: 'change'
                    }],
                    school: [{
                        required: true,
                        message: '请选择学校',
                        trigger: 'change'
                    }],
                    subject: [{
                        required: true,
                        message: '请选择学科',
                        trigger: 'change'
                    }],
                    position: [{
                        required: true,
                        message: '请选择职务',
                        trigger: 'change'
                    }],
                    userGroup: [{
                        required: true,
                        message: '请选择组别',
                        trigger: 'change'
                    }],
                }
            }
        },
        methods: {
            // 选择地址
            handleChange(value) {
                console.log(JSON.stringify(value));
                this.form.address = JSON.stringify(value)
            },
            // 完善资料确定
            async onSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    console.log('valid:', valid)
                    if (valid) {
                        console.log('完善确定');
                        let data = {
                            tempToken: this.$route.query.tempToken,
                            name: this.form.name,
                            sex: this.form.sex,
                            phone: this.form.phone,
                            idCard: this.form.idcard,
                            area: this.form.address,
                            schoolId: this.form.school,
                            subjectId: this.form.subject,
                            userGroupId: this.form.userGroup,
                            positionId: this.form.position
                        }
                        this.$Api.Login.improveInfo(data)
                            .then(resData => {
                                console.log(resData);
                                console.log('完善资料:', resData);
                                if (resData.code == 500) {
                                    this.$message.error(resData.msg);
                                    return
                                }
                                if (resData.code == 200) {
                                    this.userId = resData.data.userId;
                                    this.step = 2;
                                }

                            })
                            .catch(err => {
                                console.log('err:', err)
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

            },
            onCancel() {
                console.log('取消');
                this.$router.go(-1)
            },
            // 修改密码确定
            async onSubmitPsd() {
                if (!this.form2.psd.trim()) {
                    this.$message.error('请输入新密码');
                    return
                }
                if (!this.form2.psd_confirm.trim()) {
                    this.$message.error('请确认新密码');
                    return
                }
                if (this.form2.psd_confirm.trim() !== this.form2.psd.trim()) {
                    this.$message.error('两次输入密码不一致!');
                    return
                }
                let data = {
                    tempToken: this.$route.query.tempToken,
                    userId: this.userId,
                    password: this.form2.psd,
                    repeatPassword: this.form2.psd_confirm
                }
                this.$Api.Login.vchangePwd(data)
                    .then(resData => {
                        console.log('修改密码确定:', resData);
                        if (resData.code == 500) {
                            this.$message.error(resData.msg);
                        }
                        if (resData.code == 200) {
                            this.phone = resData.data.phone.toString().replace(
                                /(\d{3})\d{4}(\d{4})/,
                                '$1****$2');
                            this.dialogVisible = true;
                        }
                    })
                    .catch(err => {
                        console.log('err:', err)
                    })
            },
            // 修改密码取消
            // onCancelPsd() {
            //     this.form2.psd = '';
            //     this.form2.psd_confirm = '';
            //     this.step = 1
            // },
            // 虚拟账号获取信息
            async getOption() {
                let data = {
                    tempToken: this.$route.query.tempToken
                }
                let resData = await this.$Api.Login.getOption(data);
                console.log('虚拟账号获取信息:', resData);
                this.userGroup = resData.data.userGroup;
                this.position = resData.data.position;
                this.subject = resData.data.subject;
                this.address = resData.data.area
            },
            // 登录
            login() {
                this.$router.push('/login')
            }
        },
        mounted() {
            this.getOption()
        },
    };
</script>
<style lang='less' scoped>
    @import url("./less/update_userInfo.less");
</style>